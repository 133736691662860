import * as React from "react";
import Layout from "../components/layout";
import Seo from "../components/seo";
import axios from "axios";

import IconSuccess from "../components/icons/icon-success";
import IconFailed from "../components/icons/icon-failed";

const Contact = () => {
  const [formSuccess, setFormSuccess] = React.useState(false);
  const [formError, setFormError] = React.useState(false);

  const [values, setValues] = React.useState({
    name: "",
    company: "",
    email: "",
    message: "",
  });

  const sendMail = (event) => {
    event.preventDefault();
    console.log(values);
    axios
      .post("/send", values)
      .then(() => {
        setFormSuccess(true);
        setTimeout(() => {
          setFormSuccess(false);
        }, 3000);
        console.log("sent");
      })
      .catch(() => {
        setFormError(true);
        setTimeout(() => {
          setFormError(false);
        }, 3000);
        console.log("error");
      });
  };

  const handleInputChange = (event) => {
    setValues((values) => ({
      ...values,
      [event.target.id]: event.target.value,
    }));
  };

  return (
    <Layout>
      <Seo title="Contact" />

      <div className="section">
        {/* <div className="contactus-1 section-image" style="background-image: url('<?=base_url('assets/img/header.jpg')?>')"> */}
        {formSuccess && (
          <div
            id="success-notification"
            className="alert alert-success fixed-bottom fade show"
          >
            <div className="d-flex align-items-center">
              <i className="bi bi-check-circle-fill me-2"></i>
              <div>
                <strong>Envoyé!</strong> Votre message a été envoyé! Nous vous
                contactons prochainement
              </div>
            </div>
          </div>
        )}
        {formError && (
          <div
            id="failure-notification"
            className="alert alert-danger fixed-bottom fade show"
          >
            <div className="d-flex align-items-center">
              <i className="bi bi-x-circle-fill me-2"></i>
              <div>
                <strong>Erreur!</strong> Désolé, une erreur s'est produite.
                Veuillez réessayer!
              </div>
            </div>
          </div>
        )}

        <div className="container">
          <div className="col-md-8 ml-auto mr-auto text-center mb-4">
            <h2 className="title">
              <b>Demandez un devis!</b>
            </h2>
            <h4 className="description">
              Vous avez besoin de plus d'information? N'hésitez pas à nous
              contacter.
            </h4>
          </div>
          <div className="row mb-5">
            <div className="col-md-5 ml-auto mr-auto">
              <div className="mapouter">
                <div className="gmap_canvas">
                  <iframe
                    width="100%"
                    height="100%"
                    id="gmap_canvas"
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d797.7945180353868!2d10.298351721920458!3d36.886088071611994!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x0!2zMzbCsDUzJzA5LjkiTiAxMMKwMTcnNTYuMCJF!5e0!3m2!1sfr!2stn!4v1558730340136!5m2!1sfr!2stn"
                    frameBorder="1"
                    scrolling="no"
                    marginHeight="0"
                    marginWidth="0"
                  ></iframe>
                </div>
              </div>
            </div>
            <div className="col-md-5 ml-auto mr-auto">
              <div className="card card-contact card-raised">
                <form>
                  <div className="card-header text-center">
                    <h4 className="card-title">Nous contacter</h4>
                  </div>
                  <div className="card-body">
                    <div className="row">
                      <div className="col-md-6 pr-2">
                        <label htmlFor="name">Nom et prénom</label>
                        <div className="input-group">
                          <div className="input-group-prepend">
                            <span className="input-group-text">
                              <i className="nc-icon nc-single-02"></i>
                            </span>
                          </div>
                          <input
                            name="name"
                            id="name"
                            type="text"
                            className="form-control"
                            placeholder="Nom et prénom..."
                            aria-label="Nom et prénom..."
                            autoComplete="given-name"
                            value={values.name}
                            onChange={handleInputChange}
                          />
                        </div>
                      </div>
                      <div className="col-md-6 pl-2">
                        <div className="form-group">
                          <label htmlFor="company">Société</label>
                          <div className="input-group">
                            <div className="input-group-prepend">
                              <span className="input-group-text">
                                <i className="nc-icon nc-shop"></i>
                              </span>
                            </div>
                            <input
                              name="company"
                              id="company"
                              type="text"
                              className="form-control"
                              placeholder="Société..."
                              aria-label="Société..."
                              autoComplete="company"
                              value={values.company}
                              onChange={handleInputChange}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="form-group">
                      <label htmlFor="email">Adresse email</label>
                      <div className="input-group">
                        <div className="input-group-prepend">
                          <span className="input-group-text">
                            <i className="nc-icon nc-email-85"></i>
                          </span>
                        </div>
                        <input
                          name="email"
                          id="email"
                          type="email"
                          className="form-control"
                          placeholder="Adresse email..."
                          autoComplete="email"
                          value={values.email}
                          onChange={handleInputChange}
                        />
                      </div>
                    </div>
                    <div className="form-group">
                      <label htmlFor="message">Votre message</label>
                      <textarea
                        name="message"
                        className="form-control"
                        id="message"
                        value={values.message}
                        onChange={handleInputChange}
                        rows="6"
                      ></textarea>
                    </div>

                    <div className="row">
                      <div className="col-md-4">
                        <button
                          type="submit"
                          className="btn btn-danger btn-round pull-right"
                          onClick={sendMail}
                        >
                          Envoyer
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
            <div className="col-md-4">
              <center>
                <div className="info info-horizontal">
                  <div className="icon icon-neutral">
                    <i className="nc-icon nc-pin-3"></i>
                  </div>
                  <div className="description">
                    <h4 className="info-title">Rendez nous visite</h4>
                    <p className="description">
                      {" "}
                      Rue Jabeur Ibn Hayan,
                      <br />
                      Bhar Lazreg, Tunis,
                      <br />
                      Tunisie
                    </p>
                  </div>
                </div>
              </center>
            </div>
            <div className="col-md-4">
              <center>
                <div className="info info-horizontal">
                  <div className="icon icon-neutral">
                    <i className="nc-icon nc-mobile"></i>
                  </div>
                  <div className="description">
                    <h4 className="info-title">Appelez nous</h4>
                    <p className="description">
                      Fadhel Gargouri
                      <br />
                      +216 53 315 464
                      <br />
                      Lun - Ven, 8:00-18:00
                    </p>
                  </div>
                </div>
              </center>
            </div>
            <div className="col-md-4">
              <center>
                <div className="info info-horizontal">
                  <div className="icon icon-neutral">
                    <i className="nc-icon nc-planet"></i>
                  </div>
                  <div className="description">
                    <h4 className="info-title">
                      Suivez nous sur <br />
                      les réseaux sociaux
                    </h4>
                    <div style={{ height: "1em" }}></div>
                    <a
                      href="#contact"
                      className="btn btn-icon btn-neutral btn-facebook btn-round"
                    >
                      Facebook
                    </a>
                    <a
                      href="#contact"
                      className="btn btn-icon btn-neutral btn-google btn-round"
                    >
                      Google +
                    </a>
                    <a
                      href="#contact"
                      className="btn btn-icon btn-neutral btn-instagram btn-round"
                    >
                      Instagram
                    </a>
                  </div>
                </div>
              </center>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Contact;
